/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/bootstrap@4.0.0/dist/js/bootstrap.min.js
 * - /npm/wow.js@1.2.2/dist/wow.min.js
 * - /npm/superfish@1.7.9/dist/js/superfish.min.js
 * - /npm/swiper@5.2.1/js/swiper.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
